/* Color definitions */
:root {
  --black-color: #000000;
  --white-color: #FFFFFF;
  --dark-grey-color: #484848;
  --light-grey-color: #E5E5E5;
  --dark-yellow-color: #F8AC45;
  --dark-red-color: #FF606A;
  --dark-blue-color: #6FCBE9;
  --dark-green-color: #8CC546;
  --light-red-color: #931E23;
  --light-blue-color: #305AA5;
  --light-green-color: #0C8048;
  --light-outside-purple-color: #9F0F78;
  --light-outside-green-color: #035F54;
  --light-outside-brown-color: #6A512E;
  --dark-outside-purple: #B1A6FF;
}

.colorBlack {
  color: var(--black-color);
}

.info-window-close-btn {
  display: none;
}

.colorWhite {
  color: var(--white-color);
}

.colorDarkGrey {
  color: var(--dark-grey-color);
}

.colorDarkRed {
  color: var(--dark-red-color);
}

.colorDarkBlue {
  color: var(--dark-blue-color);
}

.colorDarkGreen {
  color: var(--dark-green-color);
}

.colorDarkOutsidePurple {
  color: var(--dark-outside-purple);
}

.colorDarkYellow {
  color: var(--dark-yellow-color);
}

.colorLightGrey {
  color: var(--light-grey-color);
}

.colorLightBlue {
  color: var(--light-blue-color);
}

.colorLightGreen {
  color: var(--light-green-color);
}

.colorLightRed {
  color: var(--light-red-color);
}

.backgroundDarkYellowColor {
  background-color: var(--dark-yellow-color);
}

.backgroundDarkBlueColor {
  background-color: var(--dark-blue-color);
}

.backgroundDarkGreenColor {
  background-color: var(--dark-green-color);
}

.backgroundDarkRedColor {
  background-color: var(--dark-red-color);
}

.backgroundLightRedColor {
  background-color: var(--light-red-color);
}

.backgroundLightGreenColor {
  background-color: var(--light-green-color);
}

.backgroundLightBlueColor {
  background-color: var(--light-blue-color);
}

.backgroundWhiteColor {
  background-color: var(--white-color);
}

.backgroundLightGreyColor {
  background-color: var(--light-grey-color);
}

.backgroundLightOutsidePurpleColor {
  background-color: var(--light-outside-purple-color);
}

.backgroundLightOutsideGreenColor {
  background-color: var(--light-outside-green-color);
}

.backgroundLightOutsideBrownColor {
  background-color: var(--light-outside-brown-color);
}

.backgroundDarkOutsidePurple {
  background-color: var(--dark-outside-purple);
}

.backgroundDarkGreyColor {
  background-color: var(--dark-grey-color);
}

.backgroundBlackColor {
  background-color: var(--black-color);
}

/* Shields classes */
.topShield {
  border-radius: 0 0 1rem 1rem!important;
}

/* Body definitions */
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 1.125rem;
  font-weight: 400;
  font-style: normal;
}

/* Font elements */
a {
  text-decoration: underline;
  font-weight: 700;
  color: var(--light-blue-color)!important;
}

a:hover {
  text-decoration: none;
}

a.logoLink:hover {
  opacity: .5;
}

a.avatar {
  background-color: transparent;
  width: 44px;
  height: 44px;
}

.avatarProfile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a.avatar:hover {
  opacity: .5;
}

.avatarWidth {
  width: 244px;
  height: 244px;
}

.avatarBig {
  width: 112px!important;
  height: 112px!important;
}

.minHeightSection {
  min-height: 300px;
  height: auto;
}

.socialLink {
  width: 20px;
  height: 20px
}

a.socialLink:hover {
  opacity: .5;
}

.adaYes {
  text-decoration: underline;
}

.adaNo, .noUnderline {
  text-decoration: none;
}

.fontBold {
  font-weight: 700!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Hide show classes */
.hideOnMobile {
  /* display: none; */
}


/* Border classes */
.borderAdd {
  border: 1px solid black;
}

.contentBox {
  box-shadow: none !important;
  background-color: var(--white-color)!important;
  border-radius: 0.5rem!important;
  border: 1px solid var(--light-grey-color)!important;
  color: var(--black-color)!important;
  padding: 1.25rem;
}

.boxDiv {
  box-shadow: none !important;
  background-color: var(--white-color)!important;
  border-radius: 0.5rem!important;
  border: 1px solid var(--light-grey-color)!important;
  color: var(--black-color)!important;
}

.boxDivGrey {
  box-shadow: none !important;
  background-color: var(--light-grey-color)!important;
  border-radius: 0.5rem!important;
  border: 1px solid var(--light-grey-color)!important;
  color: var(--black-color)!important;
}

.boxDivBorder:hover {
  border: 1px solid var(--light-grey-color)!important;
}

.boxDivMinHeight {
  height: auto!important;
}

.columnWidthFiveZero {
  width:68%;
}

.columnWidth50 {
  min-width:50%;
}

.cursor {
  cursor: pointer!important;
}

/* Modal classes */

.modal-card {
  box-shadow: none !important;
  background-color: var(--white-color)!important;
  border-radius: 0.5rem!important;
  border: 1px solid var(--light-grey-color)!important;
  color: var(--black-color)!important;
}

.modal-card-title {
  font-weight: bold;
}

.modal-card-foot, .modal-card-body, .modal-card-title, .modal-card-head, .modal-card {
  background-color: var(--light-grey-color)!important;
  box-shadow: none !important;
  border: none;
}

.modal-card {
  min-height: 200px;
  width: 480px;
}

/* Toast class */

.toast-container {
  box-shadow: none !important;
  background-color: var(--light-green-color)!important;
  border-radius: 0.5rem!important;
  border: 1px solid var(--light-grey-color)!important;
  color: var(--black-color)!important;
}

.toast {
  min-width: 25%;
  height: 440px;
}

/* Tags */
.tag {
  border-radius: 2rem;
  border: 1px solid var(--light-green-color);
  color: var(--light-green-color);
  background-color: var(--white-color);
  height: 24px;
}

.tagRole {
  border-radius: .24rem;
  border: none;
  color: var(--white-color);
  height: 24px;
  margin: 0;
  padding: 4px 10px 0 10px;
}

.dashboardRole {
  border-radius: .24rem;
  border: none;
  color: var(--white-color);
  height: 28px;
  padding: 4px 10px;
}

/* Card classes */
.cardDefault {
  min-width: 84%;
}

.cardDefaultHalf {
  width: 40%;
}

.cardDefault:hover {
  opacity: .80;
}

.cardHeight100 {
  height: auto;
  box-shadow: none !important;
  border-radius: 0.5rem!important;
  border: 1px solid var(--light-grey-color)!important;
  color: var(--black-color)!important;
}

.cardHeightMinH {
  min-height: 200px;
}

.cardHeightMinH400 {
  min-height: 400px;
}

.dropdown-menu {
  border: 1px solid var(--light-blue-color);
  border-radius: 0.5rem!important;
  color: var(--black-color)!important;
  background-color: var(--white-color)!important;
  margin: .5rem 0 0 0;
}

.dropdown-content, .dropdown-content>.box {
  box-shadow: none !important;
  background-color: transparent;
  border: none;
}

/* Form elements */
input, select {
  border-radius: 0.5rem!important;
  border: 1px solid var(--dark-grey-color)!important;
  cursor: pointer!important;
  height: 48px!important;
}

textarea {
  border-radius: 0.5rem!important;
  border: 1px solid var(--dark-grey-color)!important;
  cursor: pointer!important;
}

.textarea-fixed-height {
  height: 100px;
  width: 100%;
  padding: .75rem;
  min-height: 200px;
  margin-bottom: 2px;
}

.textarea-output {
  white-space: pre-wrap;
  word-wrap: break-word;
  /* background-color: #f5f5f5;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: #4a4a4a;
  padding: 0.5em;
  min-height: 120px; */
}

.formInput {
  width: 320px!important;
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio input[type="radio"] {
  margin-right: 0.5em;
  height: 48px;
  width: 18px;
  cursor: pointer;
}

.custom-radio .radio-label {
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 0.5em;
  cursor: pointer;
}

.nopad {
  margin: 0;
  padding: 0;
  height: auto;
}

.contact-actions-container {
    border: 1px solid var(--light-blue-color);
    border-radius: 0.5rem!important;
    color: var(--black-color)!important;
    background-color: var(--white-color)!important; 
}

.contact-actions-box {
  position: fixed;
  z-index: 1000;
  min-width: 200px;
  box-shadow: none;
}

.contact-actions-box a {
  border: none;
  text-align: left;
  justify-content: flex-start;
  height: 32px;
}

.inputDark, .inputLight {
  background-color: var(--white-color)!important;
  color: var(--black-color)!important;
}

.inputDark:active {
  background-color: var(--dark-red-color)!important;
  transform: translateY(1px)!important;
}

.inputDark::placeholder {
  color: var(--light-grey-color)!important;
}

.inputDark:required {
  outline: var(--dark-red-color)!important;
  color: var(--dark-red-color)!important;
}

.inputDark:focus {
  outline: 2px solid var(--dark-blue-color)!important;
}

.inputDark:hover {
  background-color: var(--light-grey-color)!important;
  outline: 2px solid var(--dark-blue-color)!important;
}

.inputLight:active, select:active, textarea:active {
  background-color: var(--light-red-color)!important;
  transform: translateY(1px)!important;
}

.inputLight::placeholder, select::placeholder, textarea::placeholder {
  color: var(--dark-grey-color)!important;
}

.inputLight:required, select:required, textarea:required {
  outline: var(--light-red-color)!important;
  color: var(--light-red-color)!important;
}

.inputLight:focus, select:focus, textarea:focus {
  outline: 2px solid var(--light-blue-color)!important;
}

input:hover, .textarea:hover, .select:hover, .inputLight:hover, select:hover, textarea:hover {
  background-color: var(--light-grey-color)!important;
  outline: 2px solid var(--light-blue-color)!important;
  background-color: initial!important;
}

input[type="checkbox"] {
  height: 14px!important;
  width: 14px!important;
  display: inline-block!important;
  line-height: 0!important;
  border-radius: 0!important;
  border: 1px solid var(--dark-grey-color)!important;
}

.file-label  {
  background-color: var(--white-color)!important;
  border-radius: 0.5rem!important;
  border: 1px solid var(--dark-grey-color)!important;
  color: var(--black-color)!important;
  cursor: pointer!important;
}

/* Button classes */
.buttonHeight {
  height: 48px!important;
}

button, .button {
  border-radius: 0.5rem!important;
  cursor: pointer!important;
  padding: 0;
  margin: 0;
  font-weight: 700!important;
  height: 48px!important;
  min-width: 6rem;
}

.editButton, .buttonMedium {
  width: 148px;
}

.buttonSmall {
  width: auto;
}

.contactLink {
  flex-direction: row!important;
  align-items: center!important;
  justify-content: end!important;
}

.descriptionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.paddingCustomOneFive {
  padding: 0 1.5rem;
}

.buttonLight {
  background-color: var(--light-blue-color)!important;
  border: 2px solid var(--light-blue-color)!important;
  color: var(--white-color)!important;
}

.buttonLight:hover {
  background-color: transparent!important;
  border: 2px solid var(--light-blue-color)!important;
  color: var(--light-blue-color)!important;
}

.buttonDark {
  background-color: var(--dark-blue-color)!important;
  border: 2px solid var(--dark-blue-color)!important;
  color: var(--black-color)!important;
}

.buttonDark:hover {
  background-color: transparent!important;
  border: 2px solid var(--dark-blue-color)!important;
  color: var(--dark-blue-color)!important;
}

.widthMedium {
  width: 12rem;
}

.custom-bullet-list {
  list-style-type: disc;
  padding: 0 .25rem;
  margin: .25rem .75rem;
}

/* Breadcrumb classes */
.breadcrumbLeft {
  padding: 1rem 0 0 2rem!important;
}

.breadcrumbRight {
  padding: 1rem 2rem 0 0!important;
}


/* Line classes */
.borderLine {
  border-bottom: 1px solid var(--dark-grey-color)!important;
  margin: 3rem 0 1rem 0;
}

.borderLineSlim {
  border-bottom: 1px solid var(--dark-grey-color)!important;
  margin: 2rem 0;
}

.searchResultsItem:hover {
  opacity: .9;
  background-color: white;
  border-radius: .5rem;
}

/* Footer component */
footer {
  background-color: var(--white-color)!important;
  border: 1px solid var(--light-grey-color)!important;
  border-radius: 0.5rem 0.5rem 0 0!important;
}

/* Navbar component */
nav {
  background-color: var(--white-color)!important;
  border-radius: 0 0 0.5rem 0.5rem!important;
  /* border: 1px solid var(--light-grey-color)!important; */
}

.navbar {
  display: flex!important;
  flex-direction: row!important;
  flex-wrap: nowrap!important;
  padding: 0.5rem 1rem!important;
  align-items: center!important;
}

.navbar-brand {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.navbar-start {
  display: none!important;
}

.navbar-menu {
  display: none!important;
}

.navbar-item {
  margin: 0;
  padding: 0;
}

.helperText, .iconSearch {
  opacity: .9;
}

/* Error classes */

/* LoginForm.css */
.input.is-danger {
  border-color: var(--dark-red-color);
}

.input.is-danger:focus {
  /* box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25); */
  box-shadow: none;
}

.help.is-danger {
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.errorInputDark {
  border: 2px solid var(--dark-red-color)!important;
  /* color: var(--light-red-color)!important; */
  /* font-weight: 700; */
}

.errorInputLight {
  border: 2px solid var(--light-red-color)!important;
  /* color: var(--light-red-color)!important; */
  /* font-weight: 700; */
}

.errorTextDark {
  color: var(--dark-red-color)!important;
  font-weight: 700;
}

.errorTextLight {
  color: var(--light-red-color)!important;
  font-weight: 700;
}

.inputDark.is-danger {
  background-color: var(--dark-red-color);
}

.buttonDark:disabled {
  opacity: 1;
  cursor: not-allowed;
}

/* Spacing classes */
.fullHeight {
  height: 100vh!important;
}

.halfHeight {
  height: 50vh!important;
}

.percentFullHeight {
  height: 100%;
}

.stretchToDiv {
  display: flex;
  height: 100%;
  width: 100%;
}

.stretchToDivInside {
  flex: 1;
}

.lh-1 {
  line-height: 1.94rem;
}

/* Tabs classes */
.tabsHeight {
  min-height: 400px;
}

/* Dashboard */
.dataBarHeight {
  min-height: 180px;
}

.dataBarSquare {
  height: 180px;
  width: 180px;
}

.borderVertical {
  border-left: 1px solid var(--light-grey-color);
  padding: 0 0 0 1rem;
  /* border-right: 1px solid var(--light-grey-color); */
}

.borderVertical:last-child {
  padding: 0;
}

.borderVerticalNo {
  border-left: none;
  border-right: none;
}



.width-6 {
  width: 15%;
}

.tabsUl {
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  border-bottom: 1px solid var(--light-blue-color)!important;
  width: 100%;
}

.tabsContent {
  flex-grow: 1;
  padding: 20px;
}

.tabs-wrapper .tabs.is-boxed li.is-active a {
  background-color: var(--light-blue-color);
  color: var(--white-color)!important;
  text-decoration: none;
  margin: 0 .5rem;
  border-color: var(--light-blue-color)!important;
  border-bottom: none!important;
  width: 124px; 
}

.tabs-wrapper .tabs.is-boxed a {
  border-color: var(--light-blue-color);
  border-bottom: none!important;
  width: 124px; 
}

.tabs-wrapper .tabs.is-boxed li:not(.is-active) a {
  background-color: var(--white-color)!important; 
  color: var(--light-blue-color)!important;
  text-decoration: none;
  margin: 0 .5rem;
}

.tabs-wrapper .tabs.is-boxed li:not(.is-active) a:hover {
  background-color: var(--light-grey-color)!important;
  border-color: var(--light-blue-color)!important;
  text-decoration: underline;
}

/* Divider classes */
.cardDivider {
  border-bottom: 1px solid var(--light-green-color);
  width: auto;
}

.containerSpacing {
  padding: 0.5rem 1rem;
}

.spacer {
  flex-grow: 1;
}

.centerStuff {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.centerStuffJustWide, .oneHundredPercentWidth {
  width: 100%;
};

.centerHorizontal {
  justify-content: center;
}

/* Table classes */
.rowHeight {
  height: 56px!important;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
}

.table th,
.table td {
  border: 1px solid #f0f0f0;
  padding: 1rem!important;
}

tr:hover {
  background-color: #f0f0f0;
  border: none!important;
  cursor: pointer;
}

.no-hover {
  background-color: transparent!important;
}

.pagination {
  background-color: transparent!important;
}

.pagination-link.is-current {
  background-color: var(--light-blue-color)!important;
  border: 2px solid var(--light-blue-color)!important;
  color: var(--white-color)!important;
  text-decoration: none;
  cursor: pointer;
}

.pagination-link.is-current:hover {
  text-decoration: none;
  background-color: transparent!important;
  border: 2px solid var(--light-blue-color)!important;
  color: var(--light-blue-color)!important;
  cursor: pointer
}

.pagination-link {
  text-decoration: none;
  background-color: transparent!important;
  border: 2px solid var(--light-blue-color)!important;
  color: var(--light-blue-color)!important;
  cursor: pointer
}

.pagination-link:hover {
  background-color: var(--light-blue-color)!important;
  border: 2px solid var(--light-blue-color)!important;
  color: var(--white-color)!important;
  text-decoration: none;
}

@media screen and (min-width: 768px) {

  .formInput {
    width: 480px!important;
  }

  .boxDivMinHeight {
    min-height: 280px;
  }

  .linkContactEditArchive {
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .cardDefault {
    min-width: 40%;
  }

  .descriptionContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  

  .avatarBig {
    width: 168px!important;
    height: 168px!important;
  }

  .navbar-brand {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .navbar-start {
    display: none;
  }
  
  .navbar-menu {
    display: none;
  }

}

@media screen and (min-width: 1024px) {  
  .contactLink {
    flex-direction: column!important;
    align-items: end!important;
    justify-content: space-between!important;
  }

  .boxDivMinHeight {
    min-height: auto;
    min-width: 400px;
  }

  .linkContactEditArchive {
    flex-direction: row;
    align-items: end;
    justify-content: center;
  }

  .cardDefaultHalf {
    width: 30%;
  }

  /* Hide show classes */
.hideOnMobile {
  border: 1px solid black;
}

.hideShow {
  display: none;
}

  .navbar-custom {
    display: none;
  }

  .avatarBig {
    width: 224px!important;
    height: 224px!important;
  }

  .navbar-start {
    display: flex!important;
    justify-content: space-between!important;
    flex: 2;
    flex-grow: 0!important;
  }

  .navbar-item {
    margin: 0 0 0 1rem!important;
    padding: 0!important;
  }
}

@media screen and (min-width: 1280px) {
  .navbar-brand {
    display: flex;
    flex: 0;
    flex-grow: 0;
    justify-content: space-between!important;
  }

  .boxDivMinHeight {
    min-height: 280px;
    /* min-height: auto; */
    min-width: auto;
  }

  .cardDefault {
    min-width: 30%;
  }

  .navbar-custom {
    display: none;
  }

  .navbar-start {
    display: flex!important;
    justify-content: center!important;
    flex: 2!important;
  }

  .search-item, .account-item {
    display: none;
  }

  .navbar-item {
    margin: 0 .75rem;
  }
  
  .navbar-menu {
    display: flex!important;
    flex: 1;
    justify-content: end;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
  .searchInput {
    width: 300px!important; 
  }

}

@media screen and (min-width: 1440px) {
  .searchInput {
    width: 360px!important; 
  }
  
  .cardDefaultHalf {
    width: 20%;
  }
}

